import { CellContext } from '@tanstack/react-table'
import { forwardRef, useMemo } from 'react'

import { useLocalization } from 'locales/i18n'
import { ShareOrderWithShares } from 'types/order'
import { Table } from 'ui/@library/data-display/table'
import { useColumnHelper } from 'ui/@library/data-display/table/use-table'
import { Loader } from 'ui/@library/feedback/loader'
import {
  AccordionContainer as Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from 'ui/@library/layout/accordion'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { useAuthUserMastersOffersReceivedLoadable } from 'ui/@store/auth-user-masters-offers-received'
import { ReviewAcceptBid } from 'ui/master/@components/modals/review-order'
import { generateNftArtBaseUrl } from 'ui/master/utils'
import { MobileTable } from 'ui/my-assets/@components/overview/@components/mobile-table'
import { StatisticType } from 'ui/my-assets/@components/overview/@components/mobile-table/types'
import { AccordionType } from 'ui/my-assets/@components/overview/types'

import { getCountDownTime } from '../../utils'
import { TableAccordionHeading } from '../table-accordion-heading'

import styles from './styles.module.scss'
import { OffersReceivedTableColumns, ShareImgType } from './types'

type OffersReceivedTableProps = {
  isAccordionOpen: string
  setIsAccordionOpen: (isAccordionOpen: string) => void
}

// eslint-disable-next-line
export const OffersReceivedTable = forwardRef<HTMLDivElement, OffersReceivedTableProps>(
  ({ isAccordionOpen, setIsAccordionOpen }, ref) => {
    const { t, f } = useLocalization('myAssets.overview.offersReceivedTable')
    const { renderModal } = useGlobalModal()
    const { authUserMastersOffersReceived, loading } = useAuthUserMastersOffersReceivedLoadable()

    const tableData = useMemo(() => {
      return authUserMastersOffersReceived.map(
        (offer: ShareOrderWithShares): OffersReceivedTableColumns => ({
          price: offer.price,
          masterUuid: offer.master.uuid,
          fractionUuid: offer.share.uuid,
          bidUuid: offer.uuid,
          thumbnail: offer.master?.assets.thumbnail,
          masterName: offer.master?.name,
          artistName: offer.master?.artist.name,
          id: `#${offer.share.tokenId}`,
          highestOffer: offer.price,
          ends: getCountDownTime(offer.endsAt),
          shareImg: {
            nftImg: generateNftArtBaseUrl(offer.master.assets.nftArtBaseUrl, offer.share.tokenId),
            thumbnail: offer.master.assets.thumbnail,
          },
          share: offer.share,
          isAcceptable: Boolean(offer.isAcceptable),
        }),
      )
      // eslint-disable-next-line
    }, [authUserMastersOffersReceived])

    const showAcceptBidModal = (offer: OffersReceivedTableColumns) => {
      renderModal({
        modalKey: ModalKey.ReviewAcceptBid,
        content: <ReviewAcceptBid />,
        enforceAuth: true,
        size: ModalSize.Large,
        payload: {
          masterUuid: offer.masterUuid,
          shareUuid: offer.share.uuid,
        },
      })
    }

    const acceptOfferButton = (cell: CellContext<OffersReceivedTableColumns, unknown>) => {
      const isAcceptable = cell.getValue() as boolean
      if (!isAcceptable) return <></>
      return (
        <p
          onClick={() => {
            showAcceptBidModal(cell.row.original)
          }}
          className={styles.buttonWrapper}
        >
          {t('columns.button')}
        </p>
      )
    }

    const showThumbnail = (cell: CellContext<OffersReceivedTableColumns, unknown>) => {
      const shareImg = cell.getValue() as ShareImgType
      return (
        <div>
          <img className={styles.thumbnailImg} src={shareImg.thumbnail} alt='' />
          <img
            className={styles.nftImg}
            src={shareImg.nftImg}
            alt=''
            onError={(e) => {
              e.currentTarget.style.display = 'none'
            }}
          />
        </div>
      )
    }

    const showAmount = (cell: CellContext<OffersReceivedTableColumns, unknown>) => {
      return <span>{f.currency(cell.getValue() as number)}</span>
    }

    const columnDefinition = [
      {
        id: 'shareImg',
        className: styles.thumbnail,
        cell: showThumbnail,
      },
      {
        id: 'masterName',
        label: t('columns.song'),
        className: styles.title,
      },
      {
        id: 'artistName',
        label: t('columns.artist'),
        className: styles.artist,
      },
      {
        id: 'id',
        label: t('columns.id'),
        className: styles.id,
        type: StatisticType.Text,
      },
      {
        id: 'highestOffer',
        label: t('columns.highestOffer'),
        className: styles.highestOffer,
        type: StatisticType.Price,
        cell: showAmount,
      },
      {
        id: 'ends',
        label: t('columns.ends'),
        className: styles.ends,
        type: StatisticType.Time,
      },
      {
        id: 'isAcceptable',
        className: styles.acceptButton,
        cell: acceptOfferButton,
      },
    ]

    const columns = useColumnHelper<OffersReceivedTableColumns>(columnDefinition)

    if (loading) return <Loader />

    return (
      <div ref={ref}>
        <Accordion setValue={setIsAccordionOpen} value={isAccordionOpen}>
          <AccordionItem value={AccordionType.OffersReceieved}>
            <AccordionHeader>
              <TableAccordionHeading title={t('title')} count={tableData.length} />
            </AccordionHeader>
            <AccordionContent>
              <p className={styles.description}>{t('description')}</p>
              <Table data={tableData} columns={columns} hideInMobile />
              <MobileTable
                columns={columnDefinition}
                tableData={tableData}
                buttonElement={<p className={styles.buttonWrapper}>{t('columns.button')}</p>}
                buttonOnClick={(item: OffersReceivedTableColumns) => {
                  showAcceptBidModal(item)
                }}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    )
  },
)
