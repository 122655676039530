import {
  sanitizeOrderFromRaw,
  sanitizeOrderBookFromRaw,
  sanitizeShareOrderFromRaw,
  sanitizeShareOrderWithSharesFromRaw,
} from 'repositories/mx-api/@santizers/order'
import { MasterBasicRaw } from 'types/master'
import { ShareWithOrdersRaw } from 'types/share'

export enum ShareOrderStatus {
  Open = 'open',
  Filled = 'filled',
  KilledNotFilled = 'killed_not_filled',
}

export enum OrderSide {
  Bid = 'bid',
  Ask = 'ask',
  ImoBid = 'imo_bid',
  ImoAsk = 'imo_ask',
}

export enum OrderType {
  LimitAsk = 'ask',
  AcceptOffer = 'accept_offer',
  ImoAsk = 'imo_ask',
  LimitBid = 'limit_bid',
  BestBid = 'best_bid',
  Bid = 'bid',
  ImoBid = 'imo_bid',
}

export type ShareOrderRaw = {
  status: ShareOrderStatus
  uuid: string
  order_type: OrderType
  order_side: OrderSide
  price: number
  ends_at?: string
  master: MasterBasicRaw
  is_cancellable?: boolean
  is_acceptable?: boolean
}

export type ShareOrderWithSharesRaw = ShareOrderRaw & {
  share: ShareWithOrdersRaw
}

export type ShareOrderWithNullableSharesRaw = ShareOrderRaw & {
  share: ShareWithOrdersRaw | null
}

export type OrderRaw = {
  uuid: string
  master: MasterBasicRaw
  order_side: OrderSide
  ends_at?: string
  share_orders: ShareOrderWithNullableSharesRaw[]
  is_cancellable: boolean
}

type OrderPriceWithVolume = {
  price: number
  quantity: number
}

export type OrderBookRaw = {
  bids: OrderPriceWithVolume[]
  asks: OrderPriceWithVolume[]
}

export type ShareOrderWithShares = ReturnType<typeof sanitizeShareOrderWithSharesFromRaw>
export type ShareOrder = ReturnType<typeof sanitizeShareOrderFromRaw>
export type Order = ReturnType<typeof sanitizeOrderFromRaw>
export type OrderBook = ReturnType<typeof sanitizeOrderBookFromRaw>
