import { CellContext } from '@tanstack/react-table'
import { forwardRef, useMemo } from 'react'

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg'
import { useLocalization } from 'locales/i18n'
import { ShareOrderWithShares } from 'types/order'
import { Table } from 'ui/@library/data-display/table'
import { useColumnHelper } from 'ui/@library/data-display/table/use-table'
import { Loader } from 'ui/@library/feedback/loader'
import {
  AccordionContainer as Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from 'ui/@library/layout/accordion'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { CancelOrderModal, CancelType } from 'ui/@modals/cancel-order-modal/cancel-order-modal'
import { useAuthUserMasterOffersMadeLoadable } from 'ui/@store/auth-user-masters-offers-made'
import { generateNftArtBaseUrl } from 'ui/master/utils'
import { MobileTable } from 'ui/my-assets/@components/overview/@components/mobile-table'
import { StatisticType } from 'ui/my-assets/@components/overview/@components/mobile-table/types'
import { AccordionType } from 'ui/my-assets/@components/overview/types'

import { getCountDownTime } from '../../utils'
import { TableAccordionHeading } from '../table-accordion-heading'

import styles from './styles.module.scss'
import { OffersMadeTableColumns, ShareImgType } from './types'

type OffersMadeTableProps = {
  isAccordionOpen: string
  setIsAccordionOpen: (isAccordionOpen: string) => void
}

// eslint-disable-next-line
export const OffersMadeTable = forwardRef<HTMLDivElement, OffersMadeTableProps>(
  ({ isAccordionOpen, setIsAccordionOpen }, ref) => {
    const { t, f } = useLocalization('myAssets.overview.offersMadeTable')
    const { renderModal } = useGlobalModal()
    const { authUserMasterOffersMade, loading } = useAuthUserMasterOffersMadeLoadable()

    const tableData = useMemo(
      () =>
        authUserMasterOffersMade.map(
          (shareOrder: ShareOrderWithShares): OffersMadeTableColumns => ({
            fractionUuid: shareOrder.share.uuid,
            bidUuid: shareOrder.uuid,
            thumbnail: shareOrder.master?.assets.thumbnail,
            masterName: shareOrder.master?.name,
            artistName: shareOrder.master?.artist.name,
            id: `#${shareOrder.share.tokenId}`,
            yourOffer: shareOrder.price,
            highestOffer: shareOrder.share.highestBidPrice || NaN,
            ends: getCountDownTime(shareOrder.endsAt),
            shareImg: {
              nftImg: generateNftArtBaseUrl(
                shareOrder.master.assets.nftArtBaseUrl,
                shareOrder.share.tokenId,
              ),
              thumbnail: shareOrder.master.assets.thumbnail,
            },
            isCancellable: Boolean(shareOrder.isCancellable),
          }),
        ),
      // eslint-disable-next-line
      [authUserMasterOffersMade],
    )

    const showCancelOfferModal = (bidData: OffersMadeTableColumns) => {
      renderModal({
        modalKey: ModalKey.CancelOrderModal,
        content: <CancelOrderModal />,
        enforceAuth: true,
        payload: {
          type: CancelType.OfferMade,
          shareUuid: bidData.fractionUuid,
          bidUuid: bidData.bidUuid,
        },
      })
    }

    const cancelOfferBtn = (cell: CellContext<OffersMadeTableColumns, unknown>) => {
      const isCancellable = cell.getValue() as boolean
      if (!isCancellable) return <></>
      return (
        <div className={styles.btnWrapper}>
          <BinIcon
            onClick={() => {
              showCancelOfferModal(cell.row.original)
            }}
          />
        </div>
      )
    }

    const showThumbnail = (cell: CellContext<OffersMadeTableColumns, unknown>) => {
      const shareImg = cell.getValue() as ShareImgType
      return (
        <div>
          <img className={styles.thumbnailImg} src={shareImg.thumbnail} alt='' />
          <img
            className={styles.nftImg}
            src={shareImg.nftImg}
            alt=''
            onError={(e) => {
              e.currentTarget.style.display = 'none'
            }}
          />
        </div>
      )
    }

    const showAmount = (cell: CellContext<OffersMadeTableColumns, unknown>) => {
      return <span>{f.currency(cell.getValue() as number)}</span>
    }

    const columnDefinition = [
      {
        id: 'shareImg',
        className: styles.thumbnail,
        cell: showThumbnail,
      },
      {
        id: 'masterName',
        label: t('columns.song'),
        className: styles.title,
      },
      {
        id: 'artistName',
        label: t('columns.artist'),
        className: styles.artist,
      },
      {
        id: 'id',
        label: t('columns.id'),
        className: styles.id,
        type: StatisticType.Text,
      },
      {
        id: 'yourOffer',
        label: t('columns.yourOffer'),
        className: styles.yourOffer,
        type: StatisticType.Price,
        cell: showAmount,
      },
      {
        id: 'highestOffer',
        label: t('columns.highestOffer'),
        className: styles.highestOffer,
        type: StatisticType.Price,
        cell: showAmount,
      },
      {
        id: 'ends',
        label: t('columns.ends'),
        className: styles.ends,
        type: StatisticType.Time,
      },
      {
        id: 'isCancellable',
        className: styles.cancelOffer,
        cell: cancelOfferBtn,
      },
    ]

    const columns = useColumnHelper<OffersMadeTableColumns>(columnDefinition)

    if (loading) return <Loader />

    return (
      <div ref={ref}>
        <Accordion value={isAccordionOpen} setValue={setIsAccordionOpen}>
          <AccordionItem value={AccordionType.OffersMade}>
            <AccordionHeader>
              <TableAccordionHeading title={t('title')} count={tableData.length} />
            </AccordionHeader>
            <AccordionContent>
              <p className={styles.description}>{t('description')}</p>
              <Table data={tableData} columns={columns} hideInMobile />
              <MobileTable
                columns={columnDefinition}
                tableData={tableData}
                buttonElement={<BinIcon className={styles.binIcon} />}
                buttonOnClick={(item: OffersMadeTableColumns) => {
                  showCancelOfferModal(item)
                }}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    )
  },
)
